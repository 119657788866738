import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import json from 'highlight.js/lib/languages/json';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule, HttpInterceptorConfig } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { FormsModule } from "@angular/forms";
import { PreviewTableComponent } from './components/preview-table/preview-table.component';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		ProfileComponent,
		NavBarComponent,
		HeroComponent,
		HomeContentComponent,
		LoadingComponent,
		ErrorComponent
	],
	imports: [
		PreviewTableComponent,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgbModule,
		HighlightModule,
		FontAwesomeModule,
		AuthModule.forRoot({
			...env.auth,
			httpInterceptor: {
				...env.httpInterceptor as unknown as HttpInterceptorConfig,
			},
		}),
		FormsModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true,
		},
		{
			provide: Window,
			useValue: window,
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					json: () => import('highlight.js/lib/languages/json'),
				},
			},
		},
	],
	bootstrap: [AppComponent],
	exports: [
		LoadingComponent
	]
})
export class AppModule {}
